<template>
  <LayoutEditor
    :header-title="form.title"
    :header-language="language"
    :show-header-title="animateTitle"
    :header-toolbar="false"
    :header-session-nav="true"
    :full-height="true"
    :back-to="'-1'"
    :header-background="hideCover"
    class="exam-view"
    :anim-header="false"
    @click-background="clickBackground"
  >
    <template v-if="hideCover" #headerToolbarPrefix>
      <div class="flex gap-4 items-center pt-1 lg:hidden">
        <ProgressIcon :progress="progress" show-percentage class="w-14 -mt-0.5" />
        <fw-button :type="'primary'" @click.native="deliverAnswers">
          <InnerLoader v-if="saving" class="mx-auto"></InnerLoader>
          <span v-else>Submeter</span></fw-button
        >
      </div>
    </template>
    <template #main-content>
      <div
        v-if="!hideCover"
        class="exam-cover transition-all duration-700 ease-in w-full h-screen  overflow-hidden fixed top-0 left-0 right-0 bottom-0 z-10"
      >
        <div
          class="cover-background w-full h-full absolute top-0 left-0 overflow-hidden transition-all duration-700 ease-in bg-gray-100"
          :class="{ 'cover-fade': starting, 'exam-ended': examEnded }"
        >
          <div class="w-full bg-exam" style="height: 110%">
            <div
              class="blob-anim w-54 h-54 absolute"
              style="--time: 20s; --amount: 9; --fill: #30D6C3; top: 58%; left: 40%; width: 34rem; margin-left: -17rem"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317.5 353.7">
                <path
                  d="M291.8 55.3c30.4 39.9 30.7 102 17 160.4-13.8 58.3-41.6 112.9-84 130.9s-99.3-.6-137-30C50.2 287.1 32 246.9 17 200.5 2.1 154.1-9.6 101.4 11.5 63.6 32.6 25.8 86.6 2.8 143.8.2c57.2-2.6 117.6 15.2 148 55.1z"
                ></path>
              </svg>
            </div>
            <div
              class="blob-anim absolute"
              style="--fill: #20a27b; --amount: 9; top: 54%; left: 43%;  width: 20rem; margin-left: -10rem"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412.3 354.7">
                <path
                  d="M320.8 58.4c47.1 38.8 97.8 95 90.8 143s-71.7 87.7-138.3 117.2c-66.5 29.5-134.7 48.7-186.4 26.5C35.3 322.9.3 259.3 0 203.3-.3 147.4 34.1 99.2 71 62.8 107.9 26.3 147.2 1.7 188.7.1c41.4-1.7 84.9 19.6 132.1 58.3z"
                ></path>
              </svg>
            </div>
            <div
              class="blob-anim reverse-anim w-54 h-54 absolute"
              style="--time: 20s; --amount: 9; --fill: #30a898; top: 50%; left: 50%; width: 34rem; margin-left: -17rem"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317.5 353.7">
                <path
                  d="M291.8 55.3c30.4 39.9 30.7 102 17 160.4-13.8 58.3-41.6 112.9-84 130.9s-99.3-.6-137-30C50.2 287.1 32 246.9 17 200.5 2.1 154.1-9.6 101.4 11.5 63.6 32.6 25.8 86.6 2.8 143.8.2c57.2-2.6 117.6 15.2 148 55.1z"
                ></path>
              </svg>
            </div>

            <div
              class="blob-anim reverse-anim absolute"
              style="--fill: #205AA2; --amount: 9; top: 58%; left: 55%;  width: 25rem; margin-left: -10rem"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412.3 354.7">
                <path
                  d="M320.8 58.4c47.1 38.8 97.8 95 90.8 143s-71.7 87.7-138.3 117.2c-66.5 29.5-134.7 48.7-186.4 26.5C35.3 322.9.3 259.3 0 203.3-.3 147.4 34.1 99.2 71 62.8 107.9 26.3 147.2 1.7 188.7.1c41.4-1.7 84.9 19.6 132.1 58.3z"
                ></path>
              </svg>
            </div>
            <div
              class="blob-anim w-54 h-54 absolute"
              style="--time: 20s; --amount: 9; --fill: #7BE5D9; top: 60%; left: 60%; width: 34rem; margin-left: -17rem"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317.5 353.7">
                <path
                  d="M291.8 55.3c30.4 39.9 30.7 102 17 160.4-13.8 58.3-41.6 112.9-84 130.9s-99.3-.6-137-30C50.2 287.1 32 246.9 17 200.5 2.1 154.1-9.6 101.4 11.5 63.6 32.6 25.8 86.6 2.8 143.8.2c57.2-2.6 117.6 15.2 148 55.1z"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <div
          class="w-full h-full flex flex-col justify-center items-center transition-all duration-700 ease-in"
          :class="{ 'cover-hide': starting }"
        >
          <div
            class="cover max-w-screen-lg mx-auto w-full flex flex-col gap-y-16 text-center justify-center justify-items-center py-20"
            :class="{ 'exam-ended': examEnded }"
          >
            <div
              v-if="firstLoading"
              class="animate-pulse h-12 rounded-md bg-gray-800 bg-opacity-10 w-1/2 sm:w-2/6 mx-auto"
            ></div>
            <div
              v-else
              class="text-3xl lg:text-4xl font-semibold items-center justify-center group gap-1 items-center mx-auto px-2"
            >
              {{ examName }}
            </div>
            <div v-if="firstLoading" class="w-5/6 sm:w-4/6 mx-auto">
              <div class="animate-pulse h-7 rounded-md bg-gray-400 bg-opacity-10 mb-3.5"></div>
              <div class="animate-pulse h-7 rounded-md bg-gray-400 bg-opacity-10 w-5/6"></div>
            </div>
            <div
              v-if="examInstructions && examInstructions.length > 7"
              class="items-center max-w-6xl col-span-2 font-normal text-sm leading-6 items-center justify-center group gap-1 items-center mx-auto  px-2"
              :class="{ 'text-left': examInstructions && examInstructions.length > 150 }"
              v-html="examInstructions"
            ></div>

            <div v-if="examEnded" class="bg-gray-50 bg-opacity-40 rounded-md p-4 text-lg text-gray-400">
              Questionário Indisponível
            </div>
            <button
              v-else
              class="start-exam-btn mx-auto h-11"
              :class="{ disabled: !canStart || loading }"
              @click="startExam"
            >
              <div v-if="!loading">
                {{
                  canStart
                    ? status === 'closed'
                      ? 'Reabrir'
                      : withAnswer
                      ? 'Continuar'
                      : 'Iniciar Questionário'
                    : 'Aguarde'
                }}
              </div>
              <InnerLoader v-else />
            </button>

            <div v-if="negativePoints || examPoints > 0 || autoMonitor" class="flex flex-col gap-5 pt-5">
              <div
                v-if="negativePoints"
                class="flex bg-yellow-100 bg-opacity-70 border border-yellow-200 p-2 rounded-md gap-2 items-center mx-auto text-sm font-semibold "
              >
                <svg
                  class="text-yellow-600 fill-current h-5 w-5 flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                  />
                </svg>
                <span class="whitespace-nowrap flex-shrink-0 text-yellow-600">
                  ATENÇÃO:
                </span>
                {{
                  limitNegativePoints
                    ? 'Selecionar opções erradas descontam na própria pergunta'
                    : 'Selecionar opções erradas descontam'
                }}
              </div>

              <div class="flex justify-around w-full gap-5 items-stretch">
                <div class="flex-1"></div>
                <div
                  v-if="false"
                  class="rounded text-sm bg-white bg-opacity-20 p-5 py-3 font-semibold max-w-sm flex  flex-col gap-1 mx-auto border border-gray-50 text-gray-600 text-left justify-center"
                >
                  <div class="font-semibold text-gray-600 text-2xl">
                    10:00
                  </div>
                  <div class="flex gap-1.5 items-center">
                    <div class="font-semibold text-gray-600">Início</div>
                    <div class="bg-gray-300 rounded-full h-1.5 w-1.5 flex-shrink-0"></div>
                    <div class="text-gray-500 text-sm">25/12/2021</div>
                  </div>
                </div>

                <div
                  v-if="examPoints > 0"
                  class="rounded text-sm bg-white bg-opacity-20 p-5 py-3 font-semibold max-w-sm flex flex-col gap-1 mx-auto border border-gray-50 text-gray-600 text-left justify-center"
                >
                  <div class="font-semibold text-gray-600 text-2xl">
                    <span class="text-gray-500">--&nbsp;/&nbsp;</span>{{ examPoints }}
                  </div>
                  <div class="font-semibold text-gray-600">Pontuação</div>
                </div>

                <div
                  v-if="autoMonitor"
                  class="rounded text-sm items-start bg-white bg-opacity-20 p-5 py-3 max-w-sm flex gap-3 mx-auto border border-gray-50 font-semibold text-gray-600"
                >
                  <div class="flex-1 text-left">
                    Este questionário usa tecnologia de monitorização automática
                    <div class="text-primary cursor-pointer hover:opacity-75 mt-1">Saber mais</div>
                  </div>

                  <div
                    class="relative rounded-full items-center flex justify-center relative text-sm px-2 py-2 inline-flex flex items-center font-bold transition-colors duration-150 rounded-lg focus:outline-none  text-primary"
                    aria-label="Monitorização automática"
                  >
                    <svg
                      class="fill-current w-7 h-7 mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M13.91 12.36L17 20.854l-2.818 1.026-3.092-8.494-4.172 3.156 1.49-14.909 10.726 10.463z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="fill-current w-7 h-7"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        d="M13 4.055c4.5.497 8 4.312 8 8.945v9H3v-9c0-4.633 3.5-8.448 8-8.945V1h2v3.055zM19 20v-7a7 7 0 0 0-14 0v7h14zm-7-2a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                      ></path>
                    </svg>
                    <span class="flex items-center justify-center h-2 w-2 absolute top-1 right-1"
                      ><span
                        class="animate-ping absolute w-full h-full inline-flex rounded-full bg-primary opacity-50"
                      ></span
                      ><span class="relative inline-flex w-full h-full rounded-full bg-primary"></span
                    ></span>
                  </div>
                </div>
                <div class="flex-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        class="mini-cover max-w-screen-lg mx-auto w-full mb-8 mt-32 bg-gray-50 flex flex-col justify-center gap-y-3"
      >
        <div
          class="text-xl lg:text-2xl text-gray-800 font-semibold gap-1 items-center px-2 text-left"
          :class="{ 'h-full text-2xl font-bold': examInstructions && examInstructions.length <= 7 }"
        >
          {{ examName }}
        </div>
        <div
          v-if="examInstructions && examInstructions.length > 150"
          class="instructions-box items-center font-normal text-sm leading-6 items-center relative justify-center group gap-1 items-center px-2 text-left relative"
          :class="{ 'expanded-instructions': expandedInstructions, 'mb-4': !expandedInstructions }"
        >
          <div class="text-gray-700 text-left instructions-text" v-html="examInstructions"></div>
          <div
            class="left-0 -bottom-4 right-0 w-full bg-gradient-to-t from-gray-50"
            :class="{ absolute: !expandedInstructions, 'mt-3': expandedInstructions }"
          >
            <button
              class=" bg-gray-50 shadow px-4 py-0.5 rounded-full border border-gray-100 font-semibold text-gray-600"
              @click="expandedInstructions = !expandedInstructions"
            >
              {{ expandedInstructions ? 'Ver menos' : 'Ver mais' }}
            </button>
          </div>
        </div>
        <div
          v-else-if="examInstructions && examInstructions.length > 7"
          class="items-center font-normal text-sm text-gray-700 text-left leading-6 items-center relative justify-center group gap-1 items-center px-2 text-left relative"
          v-html="examInstructions"
        ></div>
      </section>

      <section class="max-w-screen-lg mx-auto pb-32">
        <div class="flex gap-8">
          <div class="w-full flex-1">
            <div v-for="(page, p) in pages" :key="'page_' + p">
              <fw-form
                :id="page.key"
                :exam="true"
                :language="language"
                :form="page.schema"
                :errors="{}"
                :data="pagesState[page.key]"
                :editable="true"
                :disable-context-menu="false"
                :key-name="'key'"
                @changed="formChanged(page.key, $event)"
              ></fw-form>
            </div>
          </div>
          <div class="flex-shrink-0 w-64 hidden lg:flex">
            <div class="top-20 w-64 flex flex-col gap-3.5" :class="{ fixed: fixedlateral }">
              <div class="flex w-full flex-col gap-2">
                <fw-button
                  :type="'primary'"
                  class="py-2.5 shadow hover:shadow-md"
                  expanded
                  @click.native="deliverAnswers"
                >
                  <InnerLoader v-if="saving" class="mx-auto w-14"></InnerLoader>
                  <span v-else>Submeter Respostas</span></fw-button
                >
                <div class="relative">
                  <SavedIndicator
                    :shorter="true"
                    :dirty-data="dirtyData"
                    :saving-data="silentsaving"
                    :bold="true"
                    class="text-xs leading-4 bg-opacity-10 bottom-1.5 left-0 absolute"
                  />

                  <ProgressIcon :progress="progress" :percentage-pos="'bottom'" show-percentage class="w-full" />
                </div>
              </div>
              <div v-if="showSections" class="flex flex-col items-end gap-2">
                <div class="text-gray-400 text-sm mb-0.5 font-semibold text-right">Índice</div>
                <template v-for="page in pages">
                  <button
                    v-for="section in page.schema"
                    :key="form.key + 'bt_nav_' + section.key"
                    class=" relative w-full text-gray-600 text-sm px-0 text-right py-1 inline-flex flex items-center font-bold transition-colors duration-150 rounded-lg hover:opacity-75 focus:outline-none justify-end"
                    @click="goToSection(page.key, section.key)"
                  >
                    <div
                      v-show="typeof section.title[language] === 'undefined' || section.title[language].length === 0"
                      class="empty_nav_bt bt_nav_lateral"
                    >
                      ---
                    </div>
                    <div
                      v-show="!(typeof section.title[language] === 'undefined' || section.title[language].length === 0)"
                      class="bt_nav_lateral"
                    >
                      {{ section.title[language] }}
                    </div>
                  </button>
                </template>
              </div>
            </div>
          </div>
          <!--
           @logs="formLogs"
           -->
        </div>
      </section>
    </template>
    <template #modals>
      <b-modal
        :active.sync="showReceipt"
        full-screen
        class="h-full clear-bg"
        :can-cancel="['escape']"
        @close="showReceipt = false"
      >
        <div
          class="h-full flex flex-col bg-white bg-opacity-40 backdrop-blur-sm backdrop-filter justify-center overflow-y-auto"
          @click.self="showReceipt = false"
        >
          <div class="w-full bg-white p-2 shadow-lg mx-auto max-w-lg rounded-2xl border border-gray-100 relative">
            <button
              class="absolute top-6 right-6 rounded-full p-1 bg-gray-50 cursor-pointer text-gray-500 hover:bg-gray-200"
              @click="showReceipt = false"
            >
              <svg
                class="fill-current h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                />
              </svg>
            </button>
            <div class="rounded text-sm p-5 font-semibold flex flex-col gap-3 mx-auto text-gray-600">
              <fw-heading size="h2">Recibo da Entrega</fw-heading>

              <div>
                <fw-heading size="h4">Perguntas respondidas</fw-heading>
                <span class="text-xl">{{ answeredQuestions }}</span
                ><span class="text-lg">/{{ totalQuestions }}</span>
              </div>

              <div v-if="submittedDate !== null">
                <fw-heading size="h4">Data de entrega</fw-heading>
                <span class="text-xl">{{ submittedDate | formatDateTimeSeconds }}</span>
              </div>

              <fw-button :type="'primary'" size="xl" class="mt-2 " @click.native="goBack()">
                Fechar questionário
              </fw-button>
            </div>
          </div>
        </div>
      </b-modal>
    </template>
  </LayoutEditor>
</template>

<script>
import LayoutEditor from '../../ui/components/layouts/LayoutEditor'
import SavedIndicator from '../../ui/components/form/SavedIndicator'
//import SavedIndicator from '../../fw-modules/fw-core-vue/ui/components/form/SavedIndicator'
//import TextInput from '../../fw-modules/fw-core-vue/ui/components/form/TextInput'
import ServiceExams from '../services/ServiceExams'
import FwForm from '@/fw-modules/fw-core-vue/ui/components/form/Exam'
import InnerLoader from '../../ui/components/animation/InnerLoader'
import Vue from 'vue'
import ProgressIcon from '../../ui/components/ProgressIcon'
//import Vue from 'vue'

const inputTypes = ['multiple_choice', 'text_area']

export default {
  name: 'FormView',
  components: { InnerLoader, LayoutEditor, FwForm, ProgressIcon, SavedIndicator },
  filters: {
    time: function(value) {
      if (!value) return '00'
      if (value < 10) {
        return '0' + value
      }
      return value
    }
  }, //SavedIndicator, TextInput
  data: function() {
    return {
      fixedlateral: false,
      withAnswer: false,
      status: 'closed',
      submittedDate: null,
      showReceipt: false,
      showSections: false,
      expandedInstructions: false,
      silentsaving: false,
      saving: false,
      examEnded: false,
      language: 'pt',
      animateTitle: false,
      hideCover: false,
      starting: false,
      localStartDate: null,
      timerInterval: null,
      countdown: false,
      ellapsedHours: 0,
      ellapsedMinutes: 0,
      ellapsedSeconds: 0,
      startDate: null,
      endDate: null,
      form: {
        instructions: {
          pt: ''
        },
        title: {
          pt: ''
        },
        options: {
          logInteractions: false,
          disableRightClick: true,
          availableLanguages: ['pt'],
          anonymous: false,
          //new
          points_correct_option: 10,
          points_wrong_option: 0,

          random_multiplechoice_options: false,
          random_sections: false,
          negative_points: false,
          limit_negative_points: true,
          auto_monitor: false,
          cheat_protection: false
        }
      },
      instance: null, //load instance first
      loading: false,
      firstLoading: true,
      silentloading: false,
      pages: [],
      getStatusTimer: null,
      dirtyPages: new Set(),
      pagesState: {},
      totalQuestions: 0,
      answeredQuestions: 0,
      debouce_timer: null
    }
  },

  computed: {
    progress() {
      return this.totalQuestions > 0 && this.answeredQuestions > 0
        ? Math.floor((this.answeredQuestions / this.totalQuestions) * 100)
        : 0
    },
    canStart() {
      return this.instance && this.instance.status === 'running' ? true : false
    },
    availableLanguages() {
      return this.form && this.form.options.availableLanguages ? this.form.options.availableLanguages : []
    },
    randomMultiplechoiceOptions() {
      return this.form && this.form.options.randomMultiplechoiceOptions
        ? this.form.options.randomMultiplechoiceOptions
        : false
    },
    randomSections() {
      return this.form && this.form.options.randomSections ? this.form.options.randomSections : false
    },
    negativePoints() {
      return this.form && this.form.options.negativePoints ? this.form.options.negativePoints : false
    },
    limitNegativePoints() {
      return this.form && this.form.options.limitNegativePoints ? this.form.options.limitNegativePoints : false
    },
    examPoints() {
      return 0
    },
    autoMonitor() {
      return this.instance && this.instance.options.logInteractions ? this.instance.options.logInteractions : false
    },
    examName() {
      return this.form && this.form.title[this.language]
    },
    examInstructions() {
      return this.form && this.form.instructions[this.language]
    },
    instanceID() {
      return this.$route.params.instanceID ? this.$route.params.instanceID : null
    },
    dirtyData() {
      return this.$store.state.dirtyData
    }
  },
  created() {
    this.verifyStart()
  },
  mounted() {
    this.createScrollListner()
  },
  beforeDestroy() {
    this.createScrollListner(true)
  },
  methods: {
    createScrollListner(remove = false) {
      let mainElement = document.querySelector('main')
      let animScrollAfter = 150
      let fixLateralToolbar = 250
      let self = this
      if (remove) {
        if (mainElement) {
          mainElement.removeEventListener(
            'scroll',
            function() {
              if (mainElement.scrollTop > animScrollAfter) {
                self.animateTitle = true
              } else {
                self.animateTitle = false
              }
              if (mainElement.scrollTop > fixLateralToolbar) {
                self.fixedlateral = true
              } else {
                self.fixedlateral = false
              }
            },
            false
          )
        }
      } else {
        mainElement.addEventListener(
          'scroll',
          function() {
            if (mainElement.scrollTop > animScrollAfter) {
              self.animateTitle = true
            } else {
              self.animateTitle = false
            }
            if (mainElement.scrollTop > fixLateralToolbar) {
              self.fixedlateral = true
            } else {
              self.fixedlateral = false
            }
          },
          false
        )
      }
    },
    goBack() {
      if (this.$store.state.lastRoutePath !== null) {
        let path = this.$store.state.lastRoutePath
        this.$store.commit('setLastRoutePath', null)
        this.$router.push({ path: path })
      } else {
        this.$router.push({ path: '/' })
      }
      //this.$router.back()
    },
    goToSection(pageKey, sectionKey) {
      document.getElementsByTagName('main')[0].scrollTo({
        top:
          document.getElementById(pageKey + '_form_section_' + sectionKey).getBoundingClientRect().top +
          document.getElementsByTagName('main')[0].scrollTop -
          55,
        behavior: 'smooth'
      })
    },
    renderNav() {
      if (this.showSections) {
        let all = document.getElementsByClassName('empty_nav_bt')
        for (let i = 0; i < all.length; i++) {
          //if (all[i].classList.contains('empty_nav_bt')) {
          all[i].innerHTML = (this.language === 'pt' ? 'Secção ' : 'Section ') + (i + 1)
          //}
        }
      }
    },
    async verifyStart() {
      let self = this

      await this.getInstance()
      this.firstLoading = false
      this.getStatusTimer = setInterval(async function() {
        if (!self.silentloading && !self.examEnded) {
          await self.getInstance()
        } else if (self.examEnded) {
          clearInterval(self.getStatusTimer)
          self.getStatusTimer = null
        }
      }, 15000)
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    formChanged(pageID, data) {
      console.log('changed', pageID, data)
      let tmp = {}

      for (const [key, value] of Object.entries(data)) {
        if (!(value && (typeof value === 'string' || Array.isArray(value)) && value.length === 0)) {
          tmp[key] = value
        }
      }

      this.pagesState[pageID] = tmp
      this.dirtyPages.add(pageID)

      let self = this
      if (this.debouce_timer !== null) {
        clearTimeout(this.debouce_timer)
      }

      this.debouce_timer = setTimeout(() => {
        this.$store.commit('setDirtyData', true)
        self.debouce_timer = null
        console.log('debounced ' + pageID + ' activated!')
        self.saveAnswers()
      }, 500)
    },
    calculateProgression() {
      let numQuestionsAnswered = 0
      for (const [pagekey, page] of Object.entries(this.pagesState)) {
        for (const [inputkey, value] of Object.entries(page)) {
          console.log('evaluating ', pagekey, inputkey)
          if (value.length > 0) {
            numQuestionsAnswered++
          }
        }
      }
      this.answeredQuestions = numQuestionsAnswered
    },
    async deliverAnswers() {
      //To be used by manual action (by clicking on the send button, this does not work if the exam is already closed)
      this.saving = true
      try {
        await this.saveAnswers(true)
        await ServiceExams.submitAnswers(this.instance.key)

        let submitted = new Date()
        if (window.localStorage.getItem('submitted_' + this.instance.key)) {
          submitted = new Date(parseInt(window.localStorage.getItem('submitted_' + this.instance.key)))
        } else {
          window.localStorage.setItem('submitted_' + this.instance.key, submitted.getTime())
        }

        this.submittedDate = submitted

        //STOP
        this.stopTime()
        //TODO: Answers Report
        this.showReceipt = true
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao submeter as respostas. Tente novamente mais tarde.')
      }

      //alert('Respostas submetidas')
      this.saving = false
    },
    async saveAnswers(all = false) {
      //loop throw dirty and save
      if (!this.silentsaving || all) {
        console.log('SAVING ANSWERS all?', all)
        this.silentsaving = true
        let promises = []
        if (all) {
          this.pages.forEach(page => {
            if (this.pagesState[page.key]) {
              promises.push(ServiceExams.saveAnswer(this.instance.key, page.key, this.pagesState[page.key]))
            }
          })
        } else {
          this.dirtyPages.forEach(pageKey => {
            if (this.pagesState[pageKey]) {
              promises.push(ServiceExams.saveAnswer(this.instance.key, pageKey, this.pagesState[pageKey]))
            }
          })
        }

        this.calculateProgression()

        try {
          let result = await Promise.all(promises)
          console.log('saveAnswers', result)
          //reset dirty pages
          this.dirtyPages.clear()
          this.$store.commit('setDirtyData', false)
        } catch (e) {
          console.error('Problem saving data', e)
        }
        this.silentsaving = false
      }
    },
    async startExam() {
      if (this.canStart && this.instance && this.form) {
        this.loading = true
        let pagesPromises = []
        if (this.status === 'closed') {
          //reopen exam if it was already close
          try {
            await ServiceExams.reopenExam(this.instance.key)
          } catch (e) {
            console.error(e)
          }
        }

        this.form.pages.forEach(page => {
          pagesPromises.push(this.getPage(this.instance.key, page.key))
        })

        let totalQuestions = 0
        let answeredQuestiosn = 0
        let self = this
        Promise.all(pagesPromises)
          .then(result => {
            console.log('pagesLoaded', result)
            let num_sections = 0
            for (let p = 0; p < result.length; p++) {
              let answers = result[p].answer
              console.log(result[p].key, 'answers', answers)
              Vue.set(this.pagesState, result[p].key, answers)

              for (let s = 0; s < result[p].schema.length; s++) {
                num_sections++
                for (let i = 0; i < result[p].schema[s].content.length; i++) {
                  let type = result[p].schema[s].content[i].type
                  if (inputTypes.includes(type)) {
                    totalQuestions++
                  }
                  if (result[p].schema[s].content[i].options) {
                    for (let o = 0; o < result[p].schema[s].content[i].options.length; o++) {
                      console.log(
                        'undefined:',
                        typeof result[p].schema[s].content[i] === 'undefined',
                        typeof result[p].schema[s].content[i].options[o] === 'undefined'
                      )
                      let optionskey = result[p].schema[s].content[i].options[o].key
                      console.log('input key:', result[p].schema[s].content[i].key)
                      console.log('verifying option:', optionskey)
                      console.log('selected answers:', answers[result[p].schema[s].content[i].key])
                      if (
                        answers[result[p].schema[s].content[i].key] &&
                        Array.isArray(answers[result[p].schema[s].content[i].key]) &&
                        answers[result[p].schema[s].content[i].key].includes(optionskey)
                      ) {
                        console.log('otpion selected!')
                        answeredQuestiosn++
                        result[p].schema[s].content[i].options[o].selected = true
                      }
                    }
                  }
                }
              }
            }

            if (num_sections > 1) {
              this.showSections = true
            }
            console.log('pages result', result)
            this.pages = result
            this.loading = false
            this.totalQuestions = totalQuestions
            this.answeredQuestions = answeredQuestiosn

            //await this.loadFormData(this.instance.forms[Math.floor(Math.random() * this.instance.forms.length)].key)
            this.starting = true
            Vue.nextTick(() => {
              self.renderNav()
            })

            setTimeout(() => {
              self.hideCover = true
              //self.animateTitle = true
              //self.startTime()
            }, 700)
          })
          .catch(e => {
            this.loading = false
            this.showError('Ocorreu um erro ao carregar o questionário. Atualize a página para tentar novamente')
            console.error(e)
          })
      }
    },
    stopTime() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    },
    startTime() {
      if (window.localStorage.getItem('timer_' + this.instance.key)) {
        this.startDate = parseInt(window.localStorage.getItem('timer_' + this.instance.key))
      } else {
        this.startDate = Date.now()
        window.localStorage.setItem('timer_' + this.instance.key, this.startDate)
      }

      //Se enddate != null => countdown
      /*if (this.instance.endedDate !== null) {
        this.countdown = true
      } else {
        this.countdown = false
      }*/
      let self = this
      this.timeTick()
      this.timerInterval = setInterval(() => {
        self.timeTick()
      }, 1000)
    },
    timeTick() {
      let now = Date.now()
      let then = this.startDate
      let diff = now - then
      let hours = Math.floor(diff / 3600000)
      let minutes = Math.floor((diff % 3600000) / 60000)
      let seconds = Math.floor(((diff % 3600000) % 60000) / 1000)
      this.ellapsedHours = hours
      this.ellapsedMinutes = minutes
      this.ellapsedSeconds = seconds
    },
    async getInstance() {
      if (this.silentloading === false) {
        this.silentloading = true
        try {
          let instanceData = await ServiceExams.getExamineeInstance(this.instanceID)
          console.log('getInstance', instanceData)
          this.withAnswer = instanceData.withAnswer === true ? true : false
          this.status = instanceData.status
          this.instance = instanceData.instance
          this.form = instanceData.form

          if (instanceData.instance.status === 'ended') {
            if (this.starting) {
              //this.deliverAnswers()
              //SUBMIT
              let submitted = new Date()
              if (window.localStorage.getItem('submitted_' + this.instance.key)) {
                submitted = new Date(parseInt(window.localStorage.getItem('submitted_' + this.instance.key)))
              } else {
                window.localStorage.setItem('submitted_' + this.instance.key, submitted.getTime())
              }

              this.submittedDate = submitted

              //STOP TIME
              this.stopTime()

              this.starting = false
              let self = this
              this.$buefy.dialog.confirm({
                canCancel: false,
                title: 'Questionário indisponível',
                message:
                  'O tempo para o questionário terminou, mas não se preocupe que as suas respostas foram guardadas.',
                onConfirm: () => {
                  console.log('CONFIRMED')
                  self.showReceipt = true
                }
              })
            } else {
              this.showError('O questionário a que está a tentar aceder já terminou')
            }

            if (this.getStatusTimer !== null) {
              clearInterval(this.getStatusTimer)
              this.getStatusTimer = null
            }
            this.hideCover = false
            this.examEnded = true
          }
        } catch (e) {
          console.log('getInstance error', e)
          let error = JSON.stringify(e)
          if (error.indexOf('Forbidden') !== -1 || error.indexOf('Unauthorized') !== -1) {
            this.showError('Não tem permissões para aceder a este questionário')
          } /*else if (error.indexOf('AlreadyEnded') !== -1) {
        }*/
          this.hideCover = false
          this.examEnded = true
        }
        this.silentloading = false
      }
    },
    async getPage(instanceKey, pageKey) {
      return await ServiceExams.getExamineePage(instanceKey, pageKey)
    },
    async loadFormData(formKey) {
      this.loading = true
      try {
        let response = await ServiceExams.getForm(formKey)
        //let availableLanguages = response.options.availableLanguages

        //verify if we have current language
        if (typeof response.title[this.language] === 'undefined') {
          response.title[this.language] = ''
        }

        this.form = response

        //this.loadAnswers(this.instance.key)

        let promises = []
        //response.pages.forEach(page => promises.push(ServiceExams.getPage(page.key)))
        response.pages.forEach(page => promises.push(this.loadFormPage(page.key)))

        this.pages = []
        Promise.all(promises)
          .then(result => {
            /* console.log('pagesLoaded', result)
            for (let p = 0; p < result.length; p++) {
              let answers = result[p].answers
              console.log(result[p].key, 'answers', answers)
              Vue.set(this.pagesState, result[p].key, answers)
              for (let s = 0; s < result[p].schema.length; s++) {
                for (let i = 0; i < result[p].schema[s].content.length; i++) {
                  for (let o = 0; i < result[p].schema[s].content[i].options.length; o++) {
                    let optionskey = result[p].schema[s].content[i].options[o].key
                    console.log('verifying option:', optionskey)
                    if (answers[result[p].schema[s].content[i].key].includes(optionskey)) {
                      result[p].schema[s].content[i].options[o].selected = true
                    }
                  }
                }
              }
            }*/
            this.pages = result
            this.loading = false
            //let self = this
            /*Vue.nextTick(function() {
              setTimeout(() => {
                self.calculateRightMargin()
              }, 500)
            })*/
          })
          .catch(error => {
            console.error(error)
            this.$buefy.dialog.alert({
              message: 'Ocorreu um erro ao carregar o questionário. Tente de novo mais tarde ou contacte o suporte.',
              type: 'is-danger',
              hasIcon: false,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          })
      } catch (e) {
        console.error(e)
        this.$buefy.dialog.alert({
          message: 'Ocorreu um erro ao carregar o questionário. Tente de novo mais tarde ou contacte o suporte.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await ServiceExams.getPage(pageKey)

      if (pageResponse.answers) {
        Vue.set(this.pagesState, pageKey, pageResponse.answers)
      }

      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score
          if (typeof pageResponse.schema[s].content[i].score === 'undefined') {
            pageResponse.schema[s].content[i].score = 0
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }

              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
            }
          }
        }
      }

      return pageResponse
    },
    async loadAnswers(instanceKey) {
      let answers = await ServiceExams.getAnswers(instanceKey)
      console.log('answers', answers)
    },
    clickBackground() {
      console.log('click back')
    }
  }
}
</script>

<style>
.exam-content {
  @apply grid;
  grid-template-columns: 1fr 190px;
}
.instructions-box.expanded-instructions .instructions-text {
  max-height: none;
}
.instructions-box .instructions-text {
  max-height: 100px;
  overflow-y: hidden;
}
.exam-view {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.start-exam-btn {
  @apply rounded-md  px-5 py-2.5 font-semibold text-sm text-center text-white text-opacity-90 bg-primary uppercase;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  letter-spacing: -0.025em;
}
.start-exam-btn:hover,
.start-exam-btn:active {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.start-exam-btn.disabled {
  @apply bg-gray-200 bg-opacity-40 shadow-none cursor-not-allowed text-gray-400;
}

.blob-anim {
  animation: blob-turn var(--time, 30s) linear infinite;
  fill: var(--fill, #7600f8);
  position: relative;
  transform-origin: center;
}
.reverse-anim {
  animation-direction: reverse;
}
@media (prefers-contrast: high) {
  .blob-anim {
    display: none;
  }
}
.blob-anim svg {
  animation: blob-skew calc(var(--time, 30s) * 0.5) linear 0s infinite;
  transform-origin: center;
}
.blob-anim svg path {
  animation: blob-scale calc(var(--time, 30s) * 0.5) ease-in-out 0s infinite;
  transform-origin: center;
}
@keyframes blob-turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes blob-skew {
  0% {
    transform: skewY(0deg);
  }
  13% {
    transform: skewY(calc((1.8deg) * var(--amount, 2)));
  }
  18% {
    transform: skewY(calc((2.2deg) * var(--amount, 2)));
  }
  24% {
    transform: skewY(calc((2.48deg) * var(--amount, 2)));
  }
  25% {
    transform: skewY(calc((2.5deg) * var(--amount, 2)));
  }
  26% {
    transform: skewY(calc((2.48deg) * var(--amount, 2)));
  }
  32% {
    transform: skewY(calc((2.2deg) * var(--amount, 2)));
  }
  37% {
    transform: skewY(calc((1.8deg) * var(--amount, 2)));
  }
  50% {
    transform: skewY(0deg);
  }
  63% {
    transform: skewY(calc((-1.8deg) * var(--amount, 2)));
  }
  68% {
    transform: skewY(calc((-2.2deg) * var(--amount, 2)));
  }
  74% {
    transform: skewY(calc((-2.48deg) * var(--amount, 2)));
  }
  75% {
    transform: skewY(calc((-2.5deg) * var(--amount, 2)));
  }
  76% {
    transform: skewY(calc((-2.48deg) * var(--amount, 2)));
  }
  82% {
    transform: skewY(calc((-2.2deg) * var(--amount, 2)));
  }
  87% {
    transform: skewY(calc((-1.8deg) * var(--amount, 2)));
  }
  100% {
    transform: skewY(0deg);
  }
}
@keyframes blob-scale {
  0% {
    transform: scaleX(0.9) scaleY(1);
  }
  25% {
    transform: scaleX(0.9) scaleY(0.9);
  }
  50% {
    transform: scaleX(1) scaleY(0.9);
  }
  75% {
    transform: scaleX(0.9) scaleY(0.9);
  }
  100% {
    transform: scaleX(0.9) scaleY(1);
  }
}
.cover {
  font-weight: 700;
  letter-spacing: -0.025em;
  min-height: 520px;
  @apply text-gray-800 text-center p-5 relative;
}
.cover.exam-ended {
  @apply text-gray-400;
}
.cover-background.exam-ended {
  filter: grayscale(1);
}

.mini-cover {
  @apply border border-gray-200;
  padding: 1.5rem;
  border-radius: 1rem;
  min-height: 180px;
  z-index: 1;
  font-weight: 700;
  letter-spacing: -0.025em;
  text-align: center;
}
.bg-exam {
  filter: blur(50px);
  -webkit-filter: blur(50px);

  background: #fbfcfbff; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #b1fde6, #fbfcfbff); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #b1fde6,
    #fbfcfbff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cover-hide {
  transform: translateY(-115vh) scale(1.2);
}
.cover-fade {
  opacity: 0;
}
</style>
