import ViewHome from '@/views/ViewHome'
import ViewLogin from '@/fw-modules/fw-core-vue/id/views/LoginTablets'

import ViewExamination from '@/fw-modules/fw-core-vue/exams/views/ViewExamination'
import FormView from '@/fw-modules/fw-core-vue/exams/views/FormView'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresRole: 'user' }
  },
  {
    path: '/login',
    name: 'login',
    component: ViewLogin
  },
  {
    path: '/ex/:key',
    name: 'exams-examination',
    component: ViewExamination,
    meta: {}
  },
  {
    path: '/form/:key',
    name: 'forms-view',
    component: FormView,
    meta: {}
  }
]
