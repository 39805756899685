import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import CoreStoreDefaults from '@/fw-modules/fw-core-vue/store/defaults'

Vue.use(Vuex)

const state = Object.assign(CoreStoreDefaults.state, {
  lastActivity: null,
  inactivityTimer: null,
  inactivityTimerConfig: {
    warning1: 180000, //first warning after 3 minutes
    warning2: 270000, //second warning after 4 minutes and 30 seconds
    logout: 300000, //5 minutes inactivity logout
    ignoreRoutes: ['live', 'exams-examination']
  },
  inactivityWarnings: {
    warning1: false,
    warning2: false
  },
  inactivitySnackbar: null,
  currentRoute: null
})
state.locales = messages['pt']

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
