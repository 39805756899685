import CoreMutations from '@/fw-modules/fw-core-vue/store/mutations'

export default Object.assign(CoreMutations, {
  // Your mutations here
  setLastActivity(state, payload) {
    state.lastActivity = payload
  },
  setInactivityWarning1(state, value) {
    state.inactivityWarnings['warning1'] = value
  },
  setInactivityWarning2(state, value) {
    state.inactivityWarnings['warning2'] = value
  },
  setInactivityTimer(state, payload) {
    state.inactivityTimer = payload
  },
  setInactivitySnackbar(state, payload) {
    state.inactivitySnackbar = payload
  },
  setCurrentRoute(state, payload) {
    state.currentRoute = payload
  }
})
